@font-face {
  font-family: "ITC";
  src: url("/public/assets/fonts/ITC Avant Garde Gothic Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Alexandria:wght@500&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,300&display=swap");

@font-face {
  font-family: "Poppins", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Alexandria:wght@500&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,300&display=swap");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "ITC", "sans serif";
  font-size: 1rem;
}
