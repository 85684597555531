$light-blue: #27a6f4;
$purple: #d889ff;
$blue: #3043a0;
$dark-blue: #1e2e59;
$gradient: linear-gradient(45deg, $light-blue, $purple, $dark-blue);

@keyframes bg-animation {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}

.gradient-text {
  background-image: $gradient;
  background-size: 150%;
  animation: bg-animation 3s infinite alternate;
  background-clip: text;
  color: transparent;
  width: 100%;
}

.header-title-separator {
  margin-top: 1rem;
  background-image: linear-gradient(30deg, $light-blue, $purple);
  background-size: 200%;
  animation: bg-animation 3s infinite alternate;
}
