$light-blue: #27a6f4;
$purple: #d889ff;
$blue: #3043a0;
$dark-blue: #1e2e59;
$gradient: linear-gradient(-60deg, $light-blue, $purple, $dark-blue);

@keyframes bg-animation {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}

.gradient-btn {
  background-image: $gradient;
  background-size: 500%;
  background-position: right;
  animation: bg-animation 5s infinite alternate;
}

.white {
  color: white;
}
