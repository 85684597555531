@import "../../../utils/breakpoints.scss";

.language-selector {
  .language-selector-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    list-style: none;

    .language-selector-item-black {
      margin: 0rem 0.25rem;
      padding: 0.25rem 0.5rem;
      cursor: pointer;
      border-radius: 20px;
      color: white;
      transition: ease all 1s;
    }

    .language-selector-item {
      margin: 0rem 0.25rem;
      padding: 0.25rem 0.5rem;
      cursor: pointer;
      border-radius: 20px;
      color: black;
      transition: ease all 1s;
    }

    .globe {
      margin: 0rem 0.5rem;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      .globe-icon {
        width: 1.35rem;
        height: 1.35rem;
      }
    }
  }

  .vs {
    border-left: 2px solid black;
    height: 1.5rem;
    margin: 0rem 0.25rem;
    transition: ease all 1s;
  }

  .vs-black {
    border-left: 2px solid white;
    height: 1.5rem;
    margin: 0rem 0.25rem;
    transition: ease all 1s;
  }

  .language-selector--selected {
    padding: 0.25rem 0.5rem;
    background-color: black;
    color: white !important;
    border-radius: 20px;
    margin: 0rem 1rem;
    transition: 1s ease;
  }

  .language-selector--selected-black {
    padding: 0.25rem 0.5rem;
    background-color: white;
    color: black !important;
    border-radius: 20px;
    margin: 0rem 1rem;
    transition: 1s ease;
  }
}
