@import "../../../utils/breakpoints.scss";
@import "../../../utils/gradientText.scss";

.navbar-container {
  padding: 2rem 10vw;
  margin: 0 auto;
}

.navbar-wrapper-black {
  background-color: rgba(0, 0, 0, 0.219);
  backdrop-filter: blur(15px);
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-wrapper {
  position: relative;
  background-color: rgba(255, 255, 255, 0.603);
  backdrop-filter: blur(15px);
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logo-container {
  @include media("<=750px") {
    width: 8rem;
  }
  width: 11rem;
  padding: 0;
  cursor: pointer;
  z-index: 99999;
}

.navbar-links {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  list-style: none;

  .navbar-links-item {
    cursor: pointer;
    text-decoration: none;
    color: black;

    :hover {
      color: #b77de4;
      transition: all 300ms ease;
    }

    :not(:hover) {
      transition: all 300ms ease;
    }
  }

  .navbar-links-item-black {
    cursor: pointer;
    text-decoration: none;
    color: rgb(209, 209, 209);

    :hover {
      color: #b77de4;
      transition: all 300ms ease;
    }

    :not(:hover) {
      transition: all 300ms ease;
    }
  }

  @include media("<=1080px") {
    display: none;
  }
}

.active {
  text-decoration: none;
  list-style: none;
  color: #b77de4 !important;
  transition: all 300ms ease;
}

.inactive {
  text-decoration: none;
  list-style: none;
  transition: all 300ms ease;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  .ln-wrapper {
    @include media("<=1080px") {
      display: none;
    }
  }
}

.hamburger-container {
  @include media(">=1080px") {
    display: none;
  }
  @include media("<=1080px") {
    display: flex;
  }
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

label.hamburger {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  width: 30px; /* less width = square hamburger */
  height: 25px;
  position: relative;
  cursor: pointer;
  margin: 1rem;
}

input#hamburger {
  display: none;
}

.line {
  position: absolute;
  height: 2px;
  width: 100%;
  background: #000;
  transition: 0.5s;
}
.line-black {
  position: absolute;
  height: 2px;
  width: 100%;
  background: #fff;
  transition: 0.5s;
}
/* steps of 12 are optional, could also be 10, 13 etc */
.line:nth-child(1) {
  top: 6px;
}
.line:nth-child(2) {
  top: 12px;
}
.line:nth-child(3) {
  top: 18px;
}
.line-black:nth-child(1) {
  top: 6px;
}
.line-black:nth-child(2) {
  top: 12px;
}
.line-black:nth-child(3) {
  top: 18px;
}

/* translateY must be same as */
#hamburger:checked + .hamburger .line:nth-child(1) {
  transform: translateY(6px) rotate(-45deg);
}
#hamburger:checked + .hamburger .line-black:nth-child(1) {
  transform: translateY(6px) rotate(-45deg);
}

#hamburger:checked + .hamburger .line:nth-child(2) {
  opacity: 0;
}
#hamburger:checked + .hamburger .line-black:nth-child(2) {
  opacity: 0;
}

#hamburger:checked + .hamburger .line:nth-child(3) {
  transform: translateY(-6px) rotate(45deg);
}
#hamburger:checked + .hamburger .line-black:nth-child(3) {
  transform: translateY(-6px) rotate(45deg);
}

.links-container {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: white;
  transition: transform 0.5s ease-in-out;
  z-index: 9999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  @include media(">1080px") {
    display: none;
  }
  @include media("<=1080px") {
    display: flex;
  }
}
.links-container-black {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: black;
  transition: transform 0.5s ease-in-out;
  z-index: 9999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  @include media(">=1080px") {
    display: none;
  }
  @include media("<=1080px") {
    display: flex;
  }
}

.navigation-inner {
  padding: 3rem;
  max-width: 200px;
}

.links-container.show {
  transform: translateX(100%);
}

.links-container-black.show {
  transform: translateX(100%);
}

.ham {
  top: 1.5rem;
  right: 10px;
  z-index: 99999;
}

.dr-links-item-black {
  text-decoration: none;
  list-style: none;
  color: white;
  padding: 0.1rem;
  font-size: 3rem;
  @include media("<=600px") {
    font-size: 2rem;
  }
}

.dr-links-item {
  text-decoration: none;
  list-style: none;
  color: black;
  padding: 0.1rem;
  font-size: 3rem;
  @include media("<=600px") {
    font-size: 2rem;
  }
}
